import { Injectable, Inject } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ReplaySubject } from "rxjs";
import { first, take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../environments/environment';


@Injectable({
	providedIn: 'root'
})

export class LanguageService {

	language$ = new ReplaySubject<LangChangeEvent>(1);
  	translate = this.translateService;
  	// 國旗對照
  	countryMap = new Map().set('en', 'flag-us').set('tw', 'flag-tw');

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private translateService: TranslateService
	) { }


	setInitState() {
	    this.translateService.addLangs(environment.langs);
	    // 根據使用者的瀏覽器語言設定，如果是中文就顯示中文，否則都顯示英文
	    // 繁體/簡體中文代碼都是zh
	    //const browserLang = (this.translate.getBrowserLang().includes('zh')) ? 'tw' : 'en'  ;
	    this.setLang(environment.defaultLang);
	    this.document.documentElement.lang = environment.defaultLang; 
	 }

	setLang(lang: string) {
	    this.translateService.onLangChange.pipe(take(1)).subscribe(result => {
	      this.language$.next(result);
	    });
	    this.translateService.use(lang);
	    this.document.documentElement.lang = lang; 
	}

}
