import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router'; //接收route para
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { catchError, map, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { NotifyService } from '../services/notify.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild  {

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object, 
		private tokenService:TokenService, 
		private router:Router, 
		private logger:NGXLogger,
		private notifyService: NotifyService
	) {}
	
  	canActivate(
    	next: ActivatedRouteSnapshot,
    	state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean 
    {

    	return this.check(next, state);
    }


    canActivateChild(
    	next: ActivatedRouteSnapshot,
    	state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean 
    {
    	return this.check(next, state);
    }
    

    //進行權限檢查
    check(next, state){

    	//更新通知數量與資訊
    	//this.notifyService.update();


    	//權限代碼
    	this.logger.debug('頁面權限代碼:');
    	this.logger.debug(next.data);
		//console.log(next.data);
		let permission_page=null;
		if(next.data.permission){
			permission_page = next.data.permission;
		}


		// BROWSER
   		if (isPlatformBrowser(this.platformId)) {

			return this.tokenService.permissionCheck(permission_page).pipe(



				map(code => {
					

					switch (code) {

						case "SUCCESS":
							//更新通知數量與資訊
    						this.notifyService.update();
							return true;

						default:
							this.router.navigate(['/auth/admin/login']);
				       		return false;
					}
			      	
		      	})

	      	);
		}
	
  	}
}
