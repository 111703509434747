import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import swal from 'sweetalert2';
import { Observable, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

	timeout = 0;


  	constructor(
  		private httpService: HttpService
  	) { }


  	sendSMS(value, type='phone'){

  		if(!value){
  			return;
  		}

  		if(this.timeout!=0){
  			swal.fire({
  				icon: 'warning',
  				text: `需在等候${this.timeout}秒後 才能再次發送簡訊`
  			})
  			return false;
  		}

  		//寄簡訊
  		let params = new FormData();
  		let url;
  		if(type=='phone'){
  			//驗證手機號碼格式
  			let reg = RegExp('(09)[0-9]{8}$'); 
			if(!reg.test(value)){
				swal.fire({
					icon: 'error',
					text: '手機格式輸入錯誤'
				})
				return;
			}
  			params.append('phone',value);
  			url = `${environment.APIUrl}/api/lab-sms/sms-send-code/phone`;
  		}else if(type=='username'){
  			params.append('username',`${value}:admin`);
  			url = `${environment.APIUrl}/api/lab-sms/sms-send-code/username`;
  		}

  		this.httpService.postHttp(url, params).subscribe(
  			res => {
  				swal.fire({
  					icon: 'success',
  					text: '驗證碼已送至您的手機'
  				})

  				//重新倒數
		  		this.startTimer(environment.smsTimeout).subscribe(res => {
		  			this.timeout = environment.smsTimeout - res;
		  			console.log(this.timeout);
		  		});
  			}
  		)
  		
  		
  	}


  	//從1開始計時 timeout 為計時器停止時間
  	startTimer(timeout){
  		timeout++;
  		return timer(0,1000).pipe(
       		take(timeout),
       		map((time) => {
       			--timeout;
       			//console.log(time);
       			return time;
       		})
     	);
  	}


  	
}
