import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap, retry, reduce } from 'rxjs/operators';
import swal, { SweetAlertOptions } from 'sweetalert2';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router'; //接收route para
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})

export class HttpService {

	//開啟或關閉loading 動畫
	loadingOpen = true;
	requestCount = 0;

	constructor(
		private http: HttpClient,
		private router: Router,
		private translate: TranslateService
	) { }


	//多語系form轉成json
	langJSONEncode(forms){
		let langObject = {};
		Object.keys(forms).forEach(lang => {
            console.warn(forms[lang].value)
            langObject[lang] = forms[lang].value
        })
        return JSON.stringify(langObject);
	}


	//匯入結果呈現
	importSwal(res) {

		let text = this.translate.instant('common.import_result.failed')+': '+res.failed_count+'<br>'+
                this.translate.instant('common.import_result.comment');

		if(res.success_count){
			text = this.translate.instant('common.import_result.total')+': '+(res.success_count+res.failed_count)+'<br>'+
                this.translate.instant('common.import_result.success')+': '+res.success_count+'<br>'+text;
		}

		

		swal.fire({ 
            title: this.translate.instant('common.import_result.finish'),
            html: text,
            showCancelButton: true,
            confirmButtonText: this.translate.instant('common.import_result.record'),
            cancelButtonText: this.translate.instant('common.close')
        }).then((result) => {
		  	if(result.value) {
		  		window.open('/admin/import/log/'+res.import_record_id, '_blank');
		    	//this.router.navigate(['/admin/import/log',res.import_record_id]);
		  	}
		})
	}


	getHttp(url:string): Observable<any>{
		return this.http.get<any>(url)
					.pipe(
							//retry(3),
							//catchError(this.handleError('getHttp', ['HttpError'])
							catchError(this.handleErrorStop)
						);
	}


	getParamFormat(params): string{
		let return_param;

		if(Object.keys(params).length==1){
			let key = Object.keys(params)[0];
			return_param = `${key}=${params[key]}`;

		}else if(Object.keys(params).length>1){

			return_param = Object.keys(params).reduce((result, key, index)=> { 

				if(index==1){
					return `${result}=${params[result]}&${key}=${params[key]}&`;
				}else{
					return `${result}${key}=${params[key]}&`;
				}	
				
			});
		}
		//console.log(return_param);
		return return_param;
	}


	postHttp(url:string, formData, headers={}): Observable<any>{
		return this.http.post<any>(url, formData, headers)
				.pipe(
						//catchError(this.handleError('postHttp', ['HttpError'])
						catchError(this.handleErrorStop)
					);
	}


	deleteHttp(url:string): Observable<any>{
		return this.http.delete<any>(url)
					.pipe(
							//atchError(this.handleError('deleteHttp', ['HttpError'])
							catchError(this.handleErrorStop)
						);
	}


	putHttp(url:string, putData, headers={}): Observable<any>{
		return this.http.put<any>(url, putData, headers)
				.pipe(
						//catchError(this.handleError('putHttp', ['HttpError'])
						catchError(this.handleErrorStop)
					);
	}


	private handleErrorStop (error: Response | any) {
	    // In a real world app, we might use a remote logging infrastructure
	   
	    return Observable.create(observer => observer.error(error));;
  	}


	handleError<T> (operation = 'operation', result?: T) {


		//return Observable.throw("123");

		return (error: any): Observable<boolean> => {

			// TODO: send the error to remote logging infrastructure
			console.warn(error); // log to console instead

			// TODO: better job of transforming error for user consumption
			console.warn(`${operation} failed: ${error.message}`);

			/*
			swal({
			  title: 'Error!',
			  text: '系統出現錯誤，請通知系統管理員',
			  type: 'error',
			  confirmButtonText: '確定'
			});
			*/

			
			

			// Let the app keep running by returning an empty result.
			return of(false);
			//return of(result as T);
		};
	}
}
