<nb-layout>
 	<nb-layout-header>
	 	<div class="header">
		 	<div class="header-container">
		 	  <div class="logo-container">
		 	    <a (click)="toggle()" href="javascript:void(0);" class="sidebar-toggle">
		 	      <nb-icon icon="menu-2-outline"></nb-icon>
		 	    </a>
		 	    <a class="logo" [routerLink]="['/']">{{ 'home.title' | translate }}</a>
		 	  </div>
		 	</div>

		 	<div class="header-container">
		 	  <nb-actions size="small">

		 	  	<nb-action class="control-item">
		 	    	<nb-icon icon="clock-outline"></nb-icon>
		 	    	<div class="user-name">{{ timeout }}</div>
		 	    </nb-action>

		 	    <!--nb-action class="control-item" *ngIf="notifyService.count!=0" icon="bell-outline"
		 	    	badgeText="{{ notifyService.count }}"
		 	    	[nbContextMenu]="notifyService.list"
		 	    	nbContextMenuTag="notify-context-menu"
		 	    	badgeStatus="danger">
      			</nb-action-->
		 	    <nb-action class="control-item" (click)="authService.logout('auth/admin/login')" icon="log-out-outline"></nb-action>
		 	    <nb-action class="control-item">
		 	    	<nb-icon icon="person-outline"></nb-icon>
		 	    	<div class="user-name" *ngIf="user_info">{{ user_info.name }}</div>
		 	    </nb-action>
		 	  </nb-actions>
		 	</div>
	 	</div>
 	</nb-layout-header>

 	<nb-sidebar [responsive]=true>
 		<app-admin-menu></app-admin-menu>
 	</nb-sidebar>

 	<nb-layout-column>
 		<router-outlet></router-outlet>
 	</nb-layout-column>

 	<nb-layout-footer>
 
 		<span class="version">Version: {{ version }}</span>
 		<span class="created-by">
 		   Copyright © 2021 {{ 'home.copyright' | translate }} | Power by <b><a href="https://labspace.com.tw/" target="_blank">Lab Space 層次數位空間</a></b>
 		</span>

 	</nb-layout-footer>
</nb-layout>
