import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Subject, BehaviorSubject } from 'rxjs';
import { TokenService } from '../services/token.service';
import { catchError,switchMap,finalize,filter,take } from 'rxjs/operators';
import {  Router, ActivatedRoute, ParamMap } from '@angular/router'; //接收route para
//import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material'; //popup dialog
//import { LoginComponent } from '../auth/login/login.component';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../environments/environment';

@Injectable()
export class TokenRefreshInterceptor implements HttpInterceptor {
	refreshTokenInProgress = false;
	tokenRefreshedSource = new Subject();
	tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
	isRefreshingToken: boolean = false;
	tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	constructor(
		private logger: NGXLogger, 
		private tokenService:TokenService, 
		private router:Router, 
		//private dialog:MatDialog
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
		//this.logger.info('token refresh start');
		//this.logger.debug('tokenRefresh interceptor');
 		
 		//console.log("tokenRefresh.interceptor.ts");
			//request =this.addAuthenticationToken(request);
		return next.handle(request).pipe(
			
			catchError(err => {

				if (err instanceof HttpErrorResponse) {
					//this.logger.warn('token interc');

					//1. 非系統API回傳格式 不處理 log 錯誤訊息
					let reg = RegExp(environment.APIUrlReg);
					if(!reg.test(err.url)){
						this.logger.warn({
                			name: 'response error',
                			description: '非系統API錯誤',
                			response: err,
              			});
						return Observable.create(observer => observer.error(err));
					}

					//2. 系統API錯誤處理
					switch ((<HttpErrorResponse>err).status) {

						//toekn relative token expired
						case 419:
							

							const err_code = (<HttpErrorResponse>err).error.err_code;

							//token過期
							if(err_code == 'TOKEN_EXPIRE'){
								this.logger.info("Token過期 進行token更新");
								return this.handleTokenError(request, next);

							//token或權限不對
							}else{
								//THIS PART DOESN'T WORK
								this.logger.warn("tokenRefrsh error:419錯誤 導向login");
								//this.router.navigate(['/login']);
								//this.openDialog('/');
								return Observable.create(observer => observer.error(err));
							}
						break;
						
						default:
							this.logger.warn('HttpResponse不知名錯誤');
							return throwError((<HttpErrorResponse>err).status);
              			break;
					}
					

				//non error response
				} else {
					return next.handle(request);
				}
				
			})
			
		)
	}
	


	private handleTokenError(request: HttpRequest<any>, next: HttpHandler) {

		if(!this.isRefreshingToken) {
			this.isRefreshingToken = true;

			// Reset here so that the following requests wait until the token
			// comes back from the refreshToken call.
			this.tokenSubject.next(null);

			return this.tokenService.tokeRefresh()
				.pipe(
					switchMap(data => {
						if(data.token) {
							this.logger.debug('refresh token 回傳囉~~~~'+data)
								//this.logger.info(data)
							this.tokenSubject.next(data.token);
							const newReq =  request.clone({ headers: request.headers.set('token', data.token) });
							return next.handle(newReq);
						}

						return Observable.create(observer => observer.error('token 更新失敗1'));
					}),
					catchError(err => {
							this.logger.warn('catchError');
						return Observable.create(observer => observer.error('token 更新失敗2'));;
					}),
					finalize(() => {
						this.logger.debug('finalize') ;
						this.isRefreshingToken = false;
						return next.handle(request);
					})
				);
		} else {
			this.isRefreshingToken = false;
			return next.handle(request);
					
		}
	}

	/*
	//開啟pop up
    openDialog(data){
    	const dialogRef = this.dialog.open(LoginComponent, {
    		maxWidth: '100%',
		  	width: '500px',
		  	data: { returnUrl: data}
		});
    }
    */

}