import { Injectable } from '@angular/core';
import { CookieService } from '../services/cookie.service';
import { HttpService } from '../services/http.service';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import swal, { SweetAlertOptions } from 'sweetalert2';
import * as FormData from 'form-data';
import { NGXLogger } from 'ngx-logger';

@Injectable({
	providedIn: 'root'
})
export class TokenService {

	token_state:number = 0;


	constructor(
		private logger: NGXLogger, 
		private cookieService:CookieService, 
		private httpService:HttpService, 
		private http:HttpClient
	) { }


	//取得token
	getToken(){
		const token = localStorage.getItem('token');
		return token;
	}

	//儲存token
	setToken(token){
		localStorage.setItem('token', token);
	}


	//儲存權限
	setPermission(groups){
		localStorage.setItem('permissions', groups);
	}


	//檢查權限
	checkPermission(group, action, alert=false){
		let permissions = localStorage.getItem('permissions');
		let groups = [];

		if(permissions=='super_admin'){
			return true;
		}else{
			groups = permissions.split(',');
		}

		if(action=='edit'){
			action = 'update';
		}

		let permission = `${group}@${action}`;
		//console.warn(permission);
		const result = groups.find(item => item==permission);

		if(result){
			return true;
		}else{
			return false;
		}
	}


	//頁面驗證時 出現告警
	successCodeAlert(success_code){
		switch (success_code) {
			case "BLOCK":
				swal.fire({
					title: '尚未填寫完整會員資料',
					text: '請至會員中心，進行會員資料修改',
					icon: 'warning',
				});
			break;

			case "NO_LOGIN_PERMISSION":
				swal.fire({
					title: 'Error!',
					text: '你的帳號尚未有登入權限',
					icon: 'error',
					confirmButtonText: 'YES'
				});
			break;

			case "ACCESS_DENY":
				swal.fire({
					title: 'Error!',
					text: '你的帳號沒有此頁面的存取權限',
					icon: 'error',
					confirmButtonText: 'YES'
				});
			break;
						
			default:
							// code...
			break;
		}

		return success_code;
	}


	//guard 驗證 同時檢查頁面權限
	permissionCheck(page=null):Observable<string>{

		const token = this.getToken();
		let param = `token=${token}&check_role=admin`;
		if(page){
			param = `${param}&page=${page}`;
		}

		const url = `${environment.APIUrl}/api/lab-admin-permission/admin/user/check?${param}`;

		return this.http.get<any>(url).pipe(
			map(res => {
				//this.token_state = res.body.result;
				this.logger.debug(`infoState (guard): `);
				this.logger.debug(res);

				//每次進行token驗證時(guard) 更新user資料
				//this.memberService.setUserLogin(res);

				//如果有回傳token代表token被更新
				if(res.token){
					this.setToken(res.token);
				}

				//設定權限
				if(res.super_admin==1){
					this.setPermission('super_admin');
				}else{
					if(res.group_names){
						this.setPermission(res.group_names.join());
					}
				}


				//有sucees_code存在代表不是單純success 方案 需跳告警
				if(res.success_code){
					return this.successCodeAlert(res.success_code);
				}else{
					return 'SUCCESS';
				}
			}),
			
		);
	}


	//guard 驗證是否有填完整資料 以及是否有登入 無權限版本 
	infoState(role=null):Observable<string>{

		const token = this.getToken();
		let param = `token=${token}`;
		if(role){
			param = `${param}&role=${role}`;
		}

		const url = `${environment.APIUrl}/api/lab-auth/user/check?${param}`;

		return this.http.get<any>(url).pipe(
			map(res => {
				//this.token_state = res.body.result;
				this.logger.debug(`infoState (guard): `);
				this.logger.debug(res);

				//每次進行token驗證時(guard) 更新user資料
				//this.memberService.setUserLogin(res);

				//如果有回傳token代表token被更新
				if(res.token){
					this.setToken(res.token);
				}

				//有sucees_code存在代表不是單純success 方案 需跳告警
				if(res.success_code){
					return this.successCodeAlert(res.success_code);
				}else{
					return 'SUCCESS';
				}
			}),
			
		);
	}

	

	//intercept 更新token用
	//目前用不到
	tokeRefresh(){
		//const token = this.cookieService.getCookie('token');
		const token = this.getToken();
		let para = new FormData();
		para.append('token',token);
		const url = `${environment.APIUrl}/api/auth/refresh`;

		return this.httpService.postHttp(url, para).pipe(
	    	map(res => {
				//console.log('didn\'t get token in func');
	    		if(res.token){
					//console.log('get token in func');
					/*
	    			this.cookieService.setCookie('token',res.token);
	    			this.cookieService.setCookie('level',res.level);
					this.cookieService.setCookie('name',res.name);
					this.cookieService.setCookie('avatar',res.avatar);
					*/
	      			return res; //return the new authorization token
	      		}
	    	})
	    );
	}





	//用在component裡面 檢查是否有登入
	/*
	loginCheck():Observable<boolean>{
		//const token = this.cookieService.getCookie('token');
		const token = localStorage.getItem('token');
		let para = new FormData();
		para.append('token',token);

		const url = `${environment.APIUrl}/api/auth/check`;

		return this.http.post<any>(url, para).pipe(
			
			map(res => {
				//this.token_state = res.body.result;
				//console.warn(res);
				//回傳參數有token表示成功
				if(res.token){

					return true;

				//回傳參數沒有token表示失敗
				}else{
					//沒登入權限 需跳出警示窗
					if(res.success_code == 'NO_LOGIN_PERMISSION'){
						swal.fire({
							title: 'Error!',
							text: '你的帳號尚未有登入權限',
							icon: 'error',
							confirmButtonText: 'YES'
						});
					}
					//this.logger.warn('login check:'+this.token_state);
					this.logger.warn('loginCheck fn:');
					this.logger.warn(res);
					return false;
				}
			}),
			
		);

	}
	*/

	
}
